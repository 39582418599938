.card .table tbody td:last-child,
.card .table thead th:last-child {
  display: table-cell;
}
.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #28a745;
}
.ct-series-b .ct-slice-pie, .ct-series-b .ct-area{
  fill: #FFA534;
}