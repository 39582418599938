.react-select {
  .react-select__menu{
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top-color: #e6e6e6;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,.06);
    box-shadow: 0 1px 0 rgba(0,0,0,.06);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -1px;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 5;
    -webkit-overflow-scrolling: touch;
  }
  .react-select__menu-list {
    overflow-y: auto;
    padding-bottom: unset;
    padding-top: unset;
    position: relative;
    max-height: 200px;
  }
  .react-select__option {
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  .react-select__menu{
    .react-select__option{
      &,&:active{
        background-color: transparent;
      }
      &.react-select__option--is-selected{
        &,&:not(:first-child),&.react-select__option--is-focused:not(:first-child){
          &,&:active{
            background-color: rgba(0,126,255,.04);
            color: #333;
          }
        }
      }
      &:not(:first-child).react-select__option--is-focused:not(:first-child) {
        &,&:active{
          background-color: rgba(0,126,255,.08);
          color: #333;
        }
      }
      &.react-select__option--is-disabled {
        &,&:active{
          color: #cccccc;
          cursor: default;
        }
      }
    }
  }
  .react-select__control{
    background-color: $white-color;
    border-color: #d9d9d9 #ccc #b3b3b3;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    height: 36px;
    box-sizing: border-box;
    border-collapse: separate;
    display: table;
    width: 100%;
    &.react-select__control--menu-is-open{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.react-select__control--is-focused{
      .react-select__dropdown-indicator{
        &:after{
          transform: rotate(180deg);
          top: -4px;
        }
      }
      &,&:hover{
        border: 1px solid $primary-color;
        box-shadow: none;
      }
    }
    .react-select__value-container--has-value{
      .react-select__multi-value{
        display: inline-flex;
        border-radius: 4px;
        background-color: transparent;
        & + div:not(.react-select__multi-value){
          display: inline-flex;
        }
        .react-select__multi-value__label{
          font-size: 0.9em;
        }
      }
      .react-select__single-value{
        top: 48%;
        color: $black-color;
      }
    }
    .react-select__placeholder{
      color: $medium-dark-gray;
      line-height: 34px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .react-select__value-container,
    .react-select__indicators{
      display: table-cell;
    }
    .react-select__indicators {
      width: 60px;
      padding-right: 10px;
      align-items: flex-end;
      vertical-align: middle;
      text-align: right;
      .react-select__indicator-separator{
        display: none;
      }
      .react-select__indicator {
        padding: 0;
        display: inline-block;
      }
      .react-select__dropdown-indicator{
        &:after{
          transition: all 150ms ease 0s;
          border-color: #999 transparent transparent;
          border-style: solid;
          border-width: 5px 5px 2.5px;
          display: inline-block;
          height: 0;
          width: 0;
          position: relative;
          content: "";
        }
        &:hover:after{
          border-top-color: $link-disabled-color;
        }
        & > * {
          display: none;
        }
      }
      .react-select__clear-indicator{
        &:before{
          content: "\D7";
          display: inline-block;
          font-size: 18px;
          line-height: 1;
          top: 2px;
          position: relative;
          margin-right: 3px;
        }
        & > * {
          display: none;
        }
      }
    }
  }
  &.primary{
    .react-select__control{
      &.react-select__control--is-focused{
        &,&:hover{
          border-color: $primary-color;
        }
      }
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $primary-color;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $primary-color;
      .react-select__multi-value__remove{
        color: $primary-states-color;
        border-left: 1px solid $primary-states-color;
        &:hover{
          color: $primary-color;
          background-color: transparent;
        }
      }
    }
  }
  &.success{
    .react-select__control{
      &.react-select__control--is-focused{
        &,&:hover{
          border-color: $success-color;
        }
      }
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $success-color;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $success-color;
      .react-select__multi-value__remove{
        color: $success-states-color;
        border-left: 1px solid $success-states-color;
        &:hover{
          color: $success-color;
          background-color: transparent;
        }
      }
    }
  }
  &.info{
    .react-select__control{
      &.react-select__control--is-focused{
        &,&:hover{
          border-color: $info-color;
        }
      }
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $info-color;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $info-color;
      .react-select__multi-value__remove{
        color: $info-states-color;
        border-left: 1px solid $info-states-color;
        &:hover{
          color: $info-color;
          background-color: transparent;
        }
      }
    }
  }
  &.warning{
    .react-select__control{
      &.react-select__control--is-focused{
        &,&:hover{
          border-color: $warning-color;
        }
      }
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $warning-color;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $warning-color;
      .react-select__multi-value__remove{
        color: $warning-states-color;
        border-left: 1px solid $warning-states-color;
        &:hover{
          color: $warning-color;
          background-color: transparent;
        }
      }
    }
  }
  &.danger{
    .react-select__control{
      &.react-select__control--is-focused{
        &,&:hover{
          border-color: $danger-color;
        }
      }
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $danger-color;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $danger-color;
      .react-select__multi-value__remove{
        color: $danger-states-color;
        border-left: 1px solid $danger-states-color;
        &:hover{
          color: $danger-color;
          background-color: transparent;
        }
      }
    }
  }
  .react-select__multi-value{
    border: 1px solid $dark-gray;
    .react-select__multi-value__remove{
      position: relative;
      color: $medium-dark-gray;
      border-left: 1px solid $medium-dark-gray;
      &:hover{
        color: $dark-gray;
        background-color: transparent;
      }
    }
  }
  &.react-select--is-disabled{
    .react-select__multi-value--is-disabled{
      .react-select__multi-value__remove {
        display: none;
      }
      &.react-select__multi-value{
        background-color: #fcfcfc;
        border: 1px solid #e3e3e3;
      }
    }
    .react-select__control--is-disabled{
      background-color: #f9f9f9;
    }
  }
}
